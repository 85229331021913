

body.empty .body-background {
    background: #ff6a67;
    background: -webkit-linear-gradient(45deg,#ff6a67 0%,#f17774 50%,#c57876 100%);
    background: linear-gradient(45deg,#ff6a67 0%,#f17774 50%,#c57876 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6a67', endColorstr='#c57876', GradientType=1);
    margin: 0 auto;
}
body.empty .payment-footer {
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    color: #8694a7;
}
.body-background {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
}

.front-bg {
    position: fixed;
    width: 200%;
    height: 200%;
    left: -50%;
    background: #000;
}

.front-bg img {
    opacity: 0;
    display: block;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}



#demo-canvas{
    opacity:0.4;
}
.large-header{
    position: fixed;
}

.hideShowPassword-toggle-hide {
    background-position: -44px center;
}
.hideShowPassword-toggle, .my-toggle {
    z-index: 3;
}
.my-toggle {
    background: transparent;
    border: 0;
    border-radius: 0.25em;
    color: #888;
    cursor: pointer;
    font-size: 0.75em;
    font-weight: bold;
    margin-right: 0.5em;
    padding: 0.5em;
    text-transform: uppercase;
    -moz-appearance: none;
    -webkit-appearance: none;
}
button.my-toggle:focus {
    outline: none;
    background-color: #eee;
    color: #555;
    outline: transparent;
}
.my-toggle:hover, .my-toggle:focus {
    background-color: #eee;
    color: #555;
    outline: transparent;
}

.animation_top{
    height: 423px;
}



.horizontal-line {
    background: #88b4bd;
    height: 2px;
    width: 110px;
    margin: 0px auto;
    margin-top: 22px;
    margin-bottom: 22px;
}

.title-header{
    color: #fff;
    margin-top: 60px;
    font-size: 22px;
}


#register-password .progress{
    height:44px;
}

.card.card-login .card-content {
    padding: 5px;
}
.card.card-login {
    border-radius: 4px;
}

section.page-product-hero, section.page-front{
    padding-top: 0;
    padding-bottom: 0;
}
.page-product-hero{
    max-height: 600px;
    min-height: 600px;
    position: relative;

    margin-top: 0px;
    border: 0;
    background: #5e9aa5;
    background: -webkit-linear-gradient(45deg,#a8c4ca 0%,#97bec5 50%,#5e9aa5 100%);
    background: linear-gradient(45deg,#a8c4ca 0%,#97bec5 50%,#5e9aa5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5e9aa5', endColorstr='#5e9aa5', GradientType=1);
}

.page-front{
    max-height: 600px;
    min-height: 600px;
    position: relative;

    margin-top: 0px;
    border: 0;
    background: #5e9aa5;
    background: -webkit-linear-gradient(45deg,#a8c4ca 0%,#97bec5 50%,#5e9aa5 100%);
    background: linear-gradient(45deg,#a8c4ca 0%,#97bec5 50%,#5e9aa5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5e9aa5', endColorstr='#5e9aa5', GradientType=1);
}



.page-product-hero nav, .page-front nav{
    background-color: transparent;
}



@media only screen and (max-width: 850px) {
    .page-product-hero{
        min-height: 250px;
    }
    .animation_top{
        display: none;
    }
}
