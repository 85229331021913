@import url(https://fonts.googleapis.com/css?family=Hind:400,600);




.window {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}
.window-top {
    width: 100%;
    height: 20px;
    background-color: #E7E8E8;
    -webkit-border-top-left-radius: 0px;
    -moz-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 0px;
    -moz-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
}
.window-body {
    width: 100%;
    height: 100%;
    font-family: 'Hind', sans-serif;
    padding: 20px 30px 70px;
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    background-color: aliceblue;
}
.window-body > div {
    width: 100%;
}
.window-body > div > p {
    text-align: center;
    letter-spacing: .5px;
}

.left-btn {
    width: 50%;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 40px;
}

.right-btn {
    width: 50%;
    height: 100%;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 24px;
    line-height: 4px;
    color: #CDCDCD;
}


.window .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: left;
}

.window .footer .textinput {
    padding: 8px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    color: #d2d2d2;
}


.window-body #covermark .hellodons {
    font-family: 'Pacifico', 'cursive';
    color: #97d8e2;
    margin-right: auto;
    margin-left: 30%;
    opacity: 0.3;
}



.btn-1 {
    color: #F55647;
}

.btn-2 {
    color: #9FCB27;
}

.btn-3 {
    color: #FDBD57;
}

.bubble-wrapper {
    max-width: 100%;
    margin: 7.5px 0;
    display: flex;
    position: relative;
}

.bubble {
    max-width: 500px;
    width: max-content;
    padding: 0 15px 0 15px;
    border-radius: 10px;
    text-align: left;
    font-family: "Helvetica Neue";
    font-size: 12px;
    font-weight: normal;
}

.sender {
    border-top-left-radius: 0;
    background-color: #ee6e73;
    color: #fff;
}
.sender-wrapper {
    justify-content: flex-start;
}
.sender-wrapper img {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    border-radius: 25px;
}


.recipient {
    border-top-right-radius: 0;
    background-color: #E5E5EA;
    color: #000;
}
.recipient-wrapper {
    justify-content: flex-end;
}
.recipient-wrapper img {
    width: 30px;
    height: 30px;
    margin-left: 16px;
    border-radius: 25px;
}

.textinput span{
    animation: blink 0.8s infinite;
    color: #000;
}

.textinput p{
    color: #080808;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 30em;
    opacity: 0;
    animation: type 4s 4s steps(60, end);
}


.item-1 {
    animation: date-fade-in 0.3s 0.25s ease-in-out both, shift-upwards-1 0.4s 1.5s ease-in-out forwards, shift-upwards-2 0.4s 4s ease-in-out forwards;
}

.item-2 {
    animation: chat1-fade-in 0.3s 0.25s ease-in-out both, shift-upwards-1 0.4s 1.5s ease-in-out forwards, shift-upwards-2 0.4s 4s ease-in-out forwards;
}

.item-3 {
    animation: chat2-fade-in 0.3s 3s ease-in-out both, shift-upwards-2 0.4s 8s ease-in-out forwards;
}

.item-4 {
    animation: shift-upwards-3 0.4s 8s ease-in-out both;
}



@keyframes chat1-fade-in {
    0% {
        transform: translate(-30px, 110px);
        opacity: 0;
    }
    100% {
        transform: translate(0px, 110px);
        opacity: 1;
    }
}
@keyframes chat2-fade-in {
    0% {
        transform: translate(30px, 30px);
        opacity: 0;
    }
    100% {
        transform: translate(0px, 30px);
        opacity: 1;
    }
}

@keyframes shift-upwards-1 {
    0% {
        transform: translateY(110px);
    }
    100% {
        transform: translateY(55px);
    }
}
@keyframes shift-upwards-2 {
    0% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes shift-upwards-3 {
    0% {
        transform: translateY(40px);
        /* transform: translate(-30px, -20px);*/
        opacity: 0;
    }
    100% {
        transform: translateY(-10px);
        /*transform: translate(0px, -20px);*/
        opacity: 1;
    }
}

/* typing texte */
@keyframes type{
    from { width: 0;
        opacity: 1; }
    to {
        width: 100;opacity:1;}
}

@keyframes type_empty{
    from {width:0;}
}

@keyframes type2{
    0%{width: 0;}
    50%{width: 0;}
    100%{ width: 100; }
}

@keyframes blink{
    to{opacity: .0;}
}
