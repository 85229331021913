

.laptop:before{
    content: "";
    position: absolute;
    left: 50%;
}

.laptop:before {
    top: -10px;
    margin: -3px 0 0 -3px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #a5adbd;
}


.content {
    width: 100%;
    height: 100%;
    left: 50%;
    margin-left: -210px;
    overflow: hidden;
}

.content:before {
    content: "";
    position: absolute;
    right: -90px;
    width: 200px;
    height: 300px;
    transform: rotate(45deg);
    background: linear-gradient(to bottom, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 100%);
    z-index: 5;
}

.pg {
    width: 240px;
    height: 180px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -90px 0 0 -120px;
    background: #ffffff;
    border: 1px solid #f4f5f7;
    border-top: 20px solid #f4f5f7;
    border-radius: 5px;
    box-shadow: 0 5px 0 #e0625e;
}

.btns {
    position: absolute;
    top: -20px;
    left: 7px;
}

.btns:before {
    content: "";
    position: absolute;
    left: 35px;
    top: 5px;
    height: 10px;
    width: 185px;
    background: #fff;
    border-radius: 3px;
}

.btns > li {
    display: inline-block;
    list-style: none;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #fc5254;
    margin-right: 4px;
}

.btns li:nth-child(2) {
    background: #fcae52;
}

.btns li:nth-child(3) {
    background: #66b34e;
}

.txt {
    margin: 10px auto;
    width: 220px;
}

.txt > li {
    background: #f3f5f7;
    width: 100%;
    height: 15px;
    margin-bottom: 9px;
}

.txt > li:nth-child(2) {
    height: 60px;
}

.txt > .thin {
    height: 43px;
    width: 30%;
    margin-right: 6px;
    display: inline-block;
}

.txt > li:nth-child(5) {
    margin-right: 0;
}

.base {
    width: 90px;
    height: 50px;
    bottom: -70px;
    left: 50%;
    margin-left: -45px;
    background: #e8ebf0;
}

.base:before,
.base:after,
.grey-shadow:before,
.grey-shadow:after{
    content: "";
    position: absolute;
    top: 0;
}

.base:before {
    border-left: 13px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 50px solid #e8ebf0;
    left: -13px;
}

.base:after {
    border-right: 13px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 50px solid #e8ebf0;
    right: -13px;
}

.base > div {
    position: absolute;
}

.grey-shadow {
    width: 90px;
    height: 12px;
    background: #d8dbe1;
    top: 0;
}

.grey-shadow:before {
    border-left: 3px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 12px solid #d8dbe1;
    left: -3px;
    z-index: 2
}

.grey-shadow:after {
    border-right: 3px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 12px solid #d8dbe1;
    right: -3px;
    z-index: 2
}

.foot {
    background: #e8ebf0;
    z-index: 1;
}

.foot.top {
    width: 116px;
    height: 5px;
    bottom: -5px;
    left: 50%;
    margin-left: -58px;
}

.foot.top:before,
.foot.top:after,
.foot.bottom:before {
    content: "";
    position: absolute;
    top: 0px;
}

.foot.top:before {
    border-left: 16px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 5px solid #e8ebf0;
    left: -16px;
}

.foot.top:after {
    border-right: 16px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 5px solid #e8ebf0;
    right: -16px;
}


.foot.bottom {
    width: 150px;
    height: 5px;
    bottom: -10px;
    left: 50%;
    margin-left: -75px;
    z-index: 999;
}

.laptop {
    width: 620px;
    height: 366px;
    background: transparent;
    border: 0px solid #545454;
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 234px;
    left: 50%;
    margin-left: -415px;
}

.laptop:before {
    top: -6px;
}

.laptop > div {
    position: absolute;
}

.laptop > .content {
    width: 100%;
    height: 100%;
    left: 0;
    margin-left: 0;
}

.txt-laptop {
    width: 189px;
}

.txt-laptop > li {
    background: #fda566;
}

.txt-laptop > li:nth-child(2) {
    height: 41px;
}

.txt-laptop > .thin {
    height: 36px;
    margin-right: 5px;
}

.btm {
    width: 700px;
    height: 13px;
    bottom: -24px;
    left: 50%;
    margin-left: -350px;
    border-radius: 0 0 20px 20px;
    background: #656667;
    z-index: 1;
}

.btm:before {
    content: "";
    position: absolute;
    width: 60px;
    height: 6px;
    left: 50%;
    top: 0;
    margin-left: -30px;
    border-radius: 0 0 5px 5px;
    background: #d8dbe1;
}




.shadow {
    position: absolute;
    width: 350px;
    height: 15px;
    left: 50%;
    margin-left: -175px;
    z-index: -1;
    bottom: -20px;
    background: radial-gradient(ellipse at center, rgba(52,172,108,1) 0%,rgba(52,172,108,1) 53%,rgba(46,204,113,1) 55%,rgba(46,204,113,0) 55%,rgba(46,204,113,0) 100%);
}

.laptop .shadow {
    width: 450px;
    margin-left: -225px;
    bottom: -26px;
}





