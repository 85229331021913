.module {
    width: 300px;
    margin: 0px auto;
    border: 4px solid #EE6E73;
    border-radius: 5px;
    box-shadow: none;
}

.hidden{
    display:none;
}

body.overflow{
    overflow: hidden;
}
body.overflow footer{
    display: none;
}

.chat-wrapper {
    position: relative;
    min-height: 2000px;
}

.discussion ul#annonce{
    background-color: #fff;
    padding: 5px 15px;
    margin: 25px;
    border-radius: 10px;
    border-top-left-radius: 0;
}

.discussion ul#annonce .btn{
    margin-top: 8px;
}

.discussion ul#annonce li{
    padding: 5px;
    margin-bottom: 0;
    margin-right: 30px;
}
.discussion ul#annonce li label{
    font-size: 14px;
    color: #32556f;
    font-weight: bold;

}
.discussion ul#annonce li p{
    margin: 0;
    color: #446782;
    font-size: 13px;
}

.top-bar .close-link {
    background-color: rgba(255,255,255,0.2);
    border-radius: 10px;
    padding: 2px 12px;
    font-size: 12px;
    color: #fff;
    margin-right: 10px;
    z-index: 1000;
}

#main-container {
    position: relative;
    min-height: 100%;
    padding-top: 0;
    background-color: aliceblue;
}

form#sendmessage{
    position: relative;
}
form#sendmessage button {
    position: absolute;
    right: 0px;
    top: 0px;
    box-shadow: none;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    height: 45px;
    border-radius: 0;
}
#emoticone.modal .modal-content{
    padding:0;
}
#emoticone .emoji_container {
    display: block;
    width: 100%;
    position: relative;
    background-color: #fff;
    border: none;
    box-shadow: none;

}

#emoticone .emoji_content ul li {
    width: 65px;
    height: 65px;
    float: left;
    border: 1px solid #e3e3e3;
    margin-top: -1px;
    margin-left: -1px;
}

#detail_message .content-detail{
    margin-top: 30px;
}

li.self .messages, li.other .messages{
    cursor: pointer;
}


img.emoji_icon{
    vertical-align: middle;
    width: 48px;
    padding:3px;
}

#emoticone  .emoji_content {
    height: 100%;
     overflow-y: none;
    padding: 5px;
}


.is_bot{
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    width: 20px;
    height: 20px;
    color: #ee6e73;
    font-size: 20px;
    text-align: center;
    line-height: 16px;
    top: -5px;
}

.chat-wrapper .chat-sidebar {
    z-index: 990;
    border: none;
    position: fixed;
    width: 300px;
    left: 100%;
    margin-left: -300px;
    top: 64px;
    bottom: 0;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 78px;
    border-bottom: 1px solid #eee;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.border-right {
    border-right: 1px solid #eee;
}
.border-left {
    border-left: 1px solid #eee;
}
.border-bottom {
    border-bottom: 1px solid #eee;
}
.padding-sm {
    padding: 10px!important;
}
.chat-wrapper .chat-sidebar .friend-list {
    list-style: none;
    margin-top: 0;
}
.chat-wrapper .chat-sidebar .friend-list li.active a, .chat-wrapper .chat-sidebar  .friend-list li a:hover {
    background-color: #545454;
    color:#fff;
}
.chat-wrapper .chat-sidebar .friend-list li.active .status, .chat-wrapper .chat-sidebar  .friend-list li a:hover .status{
    color: #FFF;
}


.chat-wrapper .chat-sidebar .friend-list li {
    /*border-bottom: 1px solid #eee;*/
    position: relative;
}
.chat-wrapper .chat-sidebar .friend-list li a {
    position: relative;
    display: block;
    padding: 10px 10px 10px 10px;

    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    color: #333;
}

.chat-wrapper .chat-sidebar .friend-list li a img {
    float: left;
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border:3px solid #fff;
}
.chat-wrapper .chat-sidebar .friend-list li a em{
    margin-top: 13px;
    font-style: normal;
    font-size: 13px;
}


.chat-wrapper .chat-inner {
    position: fixed;
    top: 100px;
    right: 300px;
    left: 0;
    bottom: 25px;
    height: auto;
    padding-top: 4px;
    overflow-y: auto;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}


.chat-wrapper .chat-inner .chat-header {
    position: fixed;
    top: 64px;
    left: 444px;
    right: 0;
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    z-index: 10;
    height: 40px;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.bg-white {
    background-color: #fff;
}
.chat li img {
    width: 45px;
    height: 45px;
    border-radius: 50em;
    -moz-border-radius: 50em;
    -webkit-border-radius: 50em;
}

.chat-wrapper .chat-inner .chat-message {
    padding: 60px 20px 115px;
}
.chat li:first-child {
    margin-top: 0;
}
.chat li {
    margin: 15px 0;
}

#user-infos .content, #user-infos-profil .content{
    padding-bottom: 50px;
    padding-top: 30px;
    display: block;
    text-align: left;
}
#user-infos label{
    text-align:left;
}

#members-list .content{
    padding-bottom: 50px;
    display: inline;
    text-align: left;
    background-color: #333;
}

.chat-box {
    position: fixed;
    bottom: 0;
    left: 0;

    right: 300px;
    padding: 0;
    border-top: none;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    background-color: #fff;
}

.chat-box .smscounter{
    position: absolute;
    left: 4px;
    top: -29px;
    font-size: 10px;
    font-weight: bold;
    width: 190px;
    text-align: left;
    padding: 5px 10px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 3px;
    color: #fff;
}


#fiche-individuelle .tool-box{
    position: relative;
    width: 100%;
    border: none;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 15px;
    display: block;
    padding: 0;
}

#fiche-individuelle .tool-box .col.s12{
    padding: 0;
}

#fiche-individuelle .tool-box .btn{
    width: 100%;
    padding: 12px;
    height: 60px;
}

.tool-box {
    position: fixed;
    bottom: 0;
    width: 300px;
    margin-left: -300px;
    left: 100%;
    padding: 4px;
    border-top: none;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    border-top: 1px solid #e4e4e4;
    background-color: #e4e4e4;
}




.toolbar-bot .chip {
    position: relative;
    display: inline-block;
    height: 30px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
    line-height: 29px;
    padding: 0 12px;
    border-radius: 23px;
    background-color: #e4e4e4;
    width: auto;
    border: 0px solid #e4e4e4;
    margin: 5px;
}

.chip {
    position: relative;
    display: inline-block;
    height: 38px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
    line-height: 30px;
    padding: 0 12px;
    border-radius: 23px;
    background-color: #e4e4e4;
    width: 170px;
    border: 3px solid #e4e4e4;
    margin: 5px;
}

#show-side-chatbar{
    display: none;
}

.no-margin{
    margin: 0;
}
.no-padding{
    padding: 0;
}

li.chip.active{
    border: 3px solid rgba(238, 110, 115,0.9);
}

nav span.chat-badge.new.inverse{
    top: 15px;
    right: -45px;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}

span.chat-badge.new.inverse{
    font-weight: 300;
    font-size: 0.7rem;
    color: #ee6e73;
    background-color: #fff;
    border-radius: 50%;
    display: block;
}

span.chat-badge.new {
    font-weight: 300;
    font-size: 0.7rem;
    color: #fff;
    background-color: #ee6e73;
    border-radius: 50%;
    display: block;

}
span.chat-badge {
    height: 2rem;
    width: 2rem;
    padding: 0 6px;
    text-align: center;
    line-height: 2rem;
    color: #757575;
    position: absolute;
    right: 15px;
    box-sizing: border-box;
    top: 50%;
    margin-top: -1.1rem;
}
span.chat-badge.new:after {
    content: "";
}



.chip em{
    font-style: normal;
}

#navbar-submenu.navbar-fixed{
    height: 40px;
    z-index: 100;
} 

.top-bar {
    background: #545454;
    color: white;
    padding: 0.5rem;
    position: relative;
    overflow: hidden;
    border-bottom: none;
    position: fixed;
    z-index: 1;
    height: 40px;
    right: 300px;
    left: 0;
    top: 64px;

}
.top-bar h1 {
    display: block;
    margin: 0;
    text-align: center;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Source Code Pro';
    font-weight: normal;
    line-height: 1.5rem;
    color: #e8e8e8;
}
.top-bar .typicons-message {
    display: inline-block;
    padding: 4px 5px 2px 5px;
}
.top-bar .typicons-minus {
    position: relative;
    top: 3px;
}
.top-bar .left {
    float: left;
}
.top-bar .right {
    float: right;
    padding-top: 0;
}
.top-bar span{
    margin-right: 0px;
}
.top-bar > * {
    position: relative;
}
.top-bar.pulse::before {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    bottom: -100%;
    opacity: 0.25;
    background: radial-gradient(#ffffff, #ee6e73);
    animation: pulse 1s ease alternate infinite;
}

.loadOldMessages{
    margin: 15px;
    padding: 9px;
    text-align: center;
    display: block;
    background-color: #ffffff;
    color: #797878;
    margin-bottom: 10px;
    font-size: 12px;
    transition: background-color .3s;
    border-radius: 50px;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #8ebbe6;
}
.loadOldMessages:hover{
    padding: 9px;
    text-align: center;
    display: block;
    background-color: #7ab2ea;
    color: #fff;
    margin-bottom: 10px;
    font-size: 12px;

}

.separate-page{
    border-top: 1px solid #e8e8e8;
    margin-left: 0;
    margin-right: 0;
}

.no-message{
    padding-top: 13%;
    margin-top: -5em;
}
.no-message i.fa-5x{
    display: block;
    text-align: center;
    font-size: 6em;
    color:#ccc;
}
.no-message p{
    font-size: 2em;
    color: #ccc;
    font-family: 'Pacifico', 'cursive';
    text-align: center;
    margin-top: 10px;
}


.separate-page span{
    text-align: center;
    display: block;
    margin-top: -21px;
    background-color: #fff;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 10px;
    color: #AAA;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 50px 50px;
}

.discussion {
    list-style: none;
    margin: 0;
    padding: 0px 0px 15px;
}

.discussion ol{
    padding-left: 0px;
    margin: 0;
}

.discussion li {
    padding: 0.5rem;
    margin-bottom: 30px;

}
.discussion .avatar {
    width: 40px;
    position: relative;

}
.discussion .avatar img {
    display: block;
    width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border:1px solid #F1F1F1;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.chat-inner li.conversation:hover .avatar img{
    border:1px solid #F1F1F1;
    opacity: 0.6;
}

.other {
    justify-content: flex-end;
    align-items: flex-end;
}
.other .messages {
    order: 1;
    border-top-right-radius: 0;
}





.self .messages{
    border-top-left-radius: 0;
}
.other .avatar {
    order: 2;
}


.unread{
    -webkit-animation-duration: 2s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 10;

}
.unread.on:after, nav ul.dropdown-content a:hover .unread.on:after  {
    background: #fff;
}


.dropdown-content .unread.on:after {
    background: #ee6e73;
}


#mobile-demo .unread.off:after, #mobile-demo .unread.on:after {
    right: 60px;
    top: 15px;
}

.dropdown-content .unread.off:after, .dropdown-content .unread.on:after {
    right: 16px;
    top: 21px;
}

.unread.off:after, .unread.on:after {
    content: '';
    right: -11px;
    top: 8px;
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
}

.button-collapse .unread.off:after, .button-collapse .unread.on:after {
    content: '';
    right: -11px;
    top: 9px;
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
}

.friend-list .status {
    position: relative;
    margin-left: 14px;
    color: #a8adb3;
    margin-top: 0;
    font-size: 12px;
    line-height: 22px;
}

.chat-wrapper .chat-sidebar .friend-list li a.remove-chat{
    position: absolute;
    top: 22px;
    right: 20px;
    border-radius: 50%;
    background: #fff;
    width: 20px;
    height: 20px;
    line-height: 0px;
    padding: 2px 4px;
    color: #000;
}
.chat-wrapper .chat-sidebar .friend-list li a.remove-chat:hover{
    background-color: red;
    color: #fff;
}

.friend-list .room-elmt{
    position: relative;
}

.friend-list .on:after {
    background: #62bf6e;
}
.friend-list .off:after {
    background: #fd8064;
}
.friend-list .off:after, .friend-list .on:after {
    content: '';
    left: -12px;
    top: 8px;
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
}

li.conversation{
    list-style: none;

    -vendor-animation-duration: 0.3s;
    -vendor-animation-delay: 0.3s;

}

ol.list-messages:first-child .conversation:nth-of-type(1){
    margin-top: 20px;
    
}

.msg.browserForm{
    height: 40px;
    outline: none;
    border-radius: 0;
    border:none;
    border-top: 1px solid #e4e4e4;
    resize: none;
    box-shadow: none;
}


.self .messages{
    margin-left: 20px;
    float: left;
}
.other .messages{
    margin-right: 20px;
    background-color: #ddd;
    color:#000;
    float: right;
}

.self .avatar{
    margin-left: 20px;
    float: left;
}
.other .avatar{
    margin-right: 20px;
    background-color: transparent;
    color:#000;
    float: right;
}


.scrollable-div{
    -webkit-overflow-scrolling: touch;
}


.messages {
    background: #ee6e73;
    padding: 10px;
    border-radius: 10px;
    box-shadow: none;
    color: #fff;
    position: relative;
    max-width: 300px;
    min-width: 150px;
}

.messages time {
    color: #aab8c2;
    font-size: 11px;
    position: absolute;
    right: 0;
    bottom: -22px;
}

.messages .dlr {
    color: #aab8c2;
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: -22px;
}


.messages p {
    font-size: 0.8rem;
    margin: 0 0 0.2rem 0;
}
.messages time {
    font-size: 0.7rem;
    color: #ccc;
}

@keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}
.chat-wrapper .chat-sidebar.display, .chat-wrapper .chat-sidebar.notdisplay{
    margin-left: -300px;
    transition: all .5s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
}




@media only screen and (max-width: 500px) {
    .chat-box .smscounter {
        width: 95%;
    }
}

@media only screen and (max-width: 600px) {

    .chat-wrapper .chat-inner{
        top: 56px;
    }
    .chat-wrapper .chat-sidebar{
        top: 56px;
    }
    .top-bar{
        top: 56px;
    }
}
@media only screen and (max-width: 750px) {

    form#sendmessage button span{
        display: none;
    }

    #covermark .hellodons{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .chat-wrapper .chat-sidebar,.chat-wrapper .chat-sidebar .tool-box{
        margin-left: 0;
        box-shadow: 0 0px 4px 0px rgba(0,0,0,0.33);
    }
    .chat-wrapper .chat-inner{
        right: 0;
    }
    .chat-box{
        right: 0;
    }
    .top-bar{
        right: 0;
    }
    #show-side-chatbar{
        display: block;
        margin-top: 6px;
        height: 56px;
    }
    .discussion {
        padding: 0px 0px 10px;
    }
    .messages {
        max-width: 220px;
    }

    .chat-wrapper .chat-sidebar.display .tool-box{

    }


    /*.chat-wrapper .chat-sidebar.display .tool-box{
        display:block;
    }
    .chat-wrapper .chat-sidebar.notdisplay .tool-box{
        display:none;
    }
*/
    .chat-wrapper .chat-sidebar.display,.chat-wrapper .chat-sidebar.display .tool-box{
        margin-left: -300px;
        transition: all .5s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        -ms-transition: all .2s ease;
        -o-transition: all .2s ease;
    }
    .chat-wrapper .chat-sidebar.notdisplay, .chat-wrapper .chat-sidebar.notdisplay .tool-box{
        margin-left: 0;
        transition: all .5s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        -ms-transition: all .2s ease;
        -o-transition: all .2s ease;
    }
}
