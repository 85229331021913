
.phone-device {
    width: 100%;
    max-width: 260px;
    height: 387px;
    border: 3px solid rgb(78, 78, 78);
    border-radius: 15px 15px 0 0;
    color: #171616;
    background-color: #4e4e4e;
    margin-bottom: -37px;
    position: absolute;
    top: 213px;
}

.phone-device-camera {
    border: inherit;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 23px;
    left: 15%;
    border: 1px solid #252525;
    background-color: #252525;
}

.phone-device-speaker {
    border: inherit;
    position: absolute;
    width: 100px;
    height: 10px;
    border-radius: 5px;
    top: 26px;
    left: 50%;
    margin-left: -50px;
    border: 1px solid #252525;
    background-color: #252525;
}

.phone-device-screen-container {
    border: inherit;
    border-bottom: 0;
    position: absolute;
    top: 57px;
    width: 94%;
    left: 3%;
    height: 327px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background: rgba(255, 255, 255,1);
    border-bottom: 1px solid #e6e6e6;
}

.phone-device.center {
    left: 50%;
    margin-left: 138px;
    z-index: 100;
}

.phone-device-header {
    color: inherit;
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    z-index: 999;
    color: rgb(47, 46, 46);
    background-color: rgb(240, 240, 240);
}

.phone-device-header .bar-left{
    color: #007AFF;
    font-size: 13px;
}


.phone-device-header .title{
    margin-left: 5px;
}
.phone-device-header .title h2{
    font-size: 13px;margin-top: 3px;
    margin-bottom: 0;

}


.phone-device-header .right-dot {
    width: 20px;
    height: 20px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 24px;
    line-height: 5px;
    color: #CDCDCD;
}


.phone-device-status-bar {
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    padding: 6px;
    font-size: 12px;
    z-index: 999;
    color: rgb(47, 46, 46);
    background-color: rgb(240, 240, 240);
}

.phone-device-status-bar-dot {
    width: 5px;
    height: 5px;
    background: rgb(47, 46, 46);
    display: inline-block;
    border-radius: 50%;
    float: left;
    margin: 6px 4px 5px 0;
}

.sms-conversation {
    margin-bottom: 15px;
    text-align: left;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 60px 30px 30px;
}

.sms-conversation-bubble {
    float: right;
    margin-left: 0;
    margin-bottom: 30px;
    position: relative;
    background-color: #ACE44B;
    border-radius: 30px;
    max-width: 134px;
}

.sms-conversation-bubble.other{
    margin-left: 0;
    float: left;
}

.sms-conversation-bubble.self p {
    background-color: #31C920;
    color: #fff;
}

.sms-conversation-bubble.other p {
    background-color: #ddd;
}
/*.sms-conversation-bubble.other:after {
    border: 10px solid rgba(0,0,0,0);
    border-right: 10px solid #ddd;
    left: -14px;
    top: 5px;
}
.sms-conversation-bubble.self:after {
    border: 10px solid rgba(0,0,0,0);
    border-left: 10px solid #ACE44B;
}*/
.sms-conversation-bubble p {
    margin: 0;
    color: #2c3645;
    background: #fff;
    padding: 10px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}
.sms-conversation-bubble.other:after {
    content: "";
    position: absolute;
    height: 20px;
    bottom: 0px;
    left: -16px;
    border-left: 16px solid #fff;
    border-bottom-right-radius: 20px 35px;
}


.sms-conversation-bubble.other:before {
    content: "";
    position: absolute;
    height: 20px;
    bottom: 0px;
    left: -10px;
    border-left: 20px solid #E5E5EA;
    border-bottom-right-radius: 16px 14px;
}


.sms-conversation-bubble.self:before {
    content: "";
    position: absolute;
    height: 20px;
    bottom: 0px;
    right: -15px;
    border-left: 25px solid #30c81f;
    border-bottom-left-radius: 16px 14px;
}

.sms-conversation-bubble.self:after {
    content: "";
    position: absolute;
    height: 20px;
    bottom: 0px;
    right: -16px;
    border-left: 16px solid #fff;
    border-bottom-left-radius: 20px 35px;
}





.item-1-phone {
    animation: chat1phone-fade-in 0.5s 1s ease-in-out both, shift1phone-upwards-2 0.5s 1s ease-in-out forwards;
}

.item-2-phone {
    animation: chat2phone-fade-in 0.5s 9.5s ease-in-out both, shift2phone-upwards-2 0.5s 9.5s ease-in-out forwards;
}

@keyframes chat1phone-fade-in {
    0% {
        /*transform: translate(30px, 55px);*/
        opacity: 0;
    }
    100% {
        /*transform: translate(0px, 55px);*/
        opacity: 1;
    }
}
@keyframes shift1phone-upwards-2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(200px);
    }
}

@keyframes chat2phone-fade-in {
    0% {
        /*transform: translate(30px, 55px);*/
        opacity: 0;
    }
    100% {
        /*transform: translate(0px, 55px);*/
        opacity: 1;
    }
}
@keyframes shift2phone-upwards-2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(130px);
    }
}

